import {
  params, shape, string, arrayOf
} from '@thd-nucleus/data-sources';

const SpecificationGroup = arrayOf(shape({
  specTitle: string(),
  specifications: arrayOf(shape({
    specName: string(),
    specValue: string()
  }))
}));

export const dataModel = {
  products: params({
    itemIds: arrayOf(string().isRequired()).isRequired(),
    dataSource: string()
  }).arrayOf(
    shape({
      itemId: string(),
      specificationGroup: SpecificationGroup
    })
  )
};