import React from 'react';
import { arrayOf, string, func } from 'prop-types';
import {
  useDataModel,
  extend,
  QueryProvider,
} from '@thd-nucleus/data-sources';
import { useProductDeprecated } from '@thd-nucleus/data-sources/react/dataModel/migration';
// eslint-disable-next-line import/no-cycle
import { CompareSpecifications, CompareStickyHeader } from '../compare';

const CompareCatalogLoader = ({ itemIds, storeId, children }) => {
  /*
  const opts = {
    ssr: false,
    skip: storeId === '8119',
    variables: {
      itemIds,
      storeId
    }
  };

  const response = useDataModel('products', opts);
  */

  const domains = ['specificationGroup'];
  const { error, loading, data } = useProductDeprecated({
    domains,
    itemIds,
    ssr: false
  }, 'products');

  if (!data) return null;

  return (
    <QueryProvider
      cacheKey="compare-catalog-loader"
      defaultVariables={{
        storeId,
        skipInstallServices: false,
        skipSpecificationGroup: false
      }}
    >
      {children({ data })}
    </QueryProvider>
  );
};

CompareCatalogLoader.displayName = 'CompareCatalogLoader';

CompareCatalogLoader.propTypes = {
  itemIds: arrayOf(string),
  storeId: string,
  children: func.isRequired
};

CompareCatalogLoader.defaultProps = {
  itemIds: [],
  storeId: ''
};

CompareCatalogLoader.dataModel = extend({}, CompareSpecifications, CompareStickyHeader);

export { CompareCatalogLoader };
