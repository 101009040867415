import React, { useContext } from 'react';
import { arrayOf, string, func, bool } from 'prop-types';
import { Checkbox } from '@thd-olt-component-react/checkbox';
import { useStore, ExperienceContext } from '@thd-nucleus/experience-context';
import { extend } from '@thd-nucleus/data-sources';
import { CustomProductPod } from './CustomProductPod';
import './compare-pods.scss';

const ComparePods = ({
  hasInStoreFilter,
  hideFavorites,
  hideRebates,
  hideSimilar,
  itemIds,
  onHideSimilar,
  onRemove
}) => {

  const { channel } = useContext(ExperienceContext);
  const { storeId } = useStore();

  const { customer } = useContext(ExperienceContext);
  const isB2BCustomer = customer?.type === 'b2b';

  if (channel === 'desktop') {
    return (
      <div className="compare-pods" data-components="ComparePodsDesktop">
        <div className="compare-pods__wrapper">
          <div className="compare-pods__section">
            <div className="compare-pods__section-title">
              Compare Products
            </div>
            <div data-testid="hide-similar-pods" className="compare-pods__hide-similar">
              <Checkbox
                id="hide-similar"
                onChange={onHideSimilar}
                checked={hideSimilar}
                value="hide-similar"
              >
                Hide Similarities
              </Checkbox>
            </div>
          </div>
          {itemIds.map((itemId, index) => (
            <div className="compare-pods__section compare-pods__section--pod" key={`image-${index}`}>
              <CustomProductPod
                hasInStoreFilter={hasInStoreFilter}
                hideFavorites={hideFavorites}
                isB2BCustomer={isB2BCustomer}
                itemId={itemId}
                onRemove={onRemove}
                position={index}
                removable={itemIds.length > 2}
                storeId={storeId}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="compare-pods" data-components="ComparePodsMobile">
      <div className="compare-pods__wrapper">
        <div className="compare-pods__section compare-pods__section--mobile">
          <div className="compare-pods__section-title">
            <span>
              Compare Products
            </span>
          </div>
          <div className="compare-pods__hide-similar">
            <span data-testid="hide-similar-pods">
              <Checkbox
                id="hide-similar-pods"
                isB2BCustomer={isB2BCustomer}
                onChange={onHideSimilar}
                checked={hideSimilar}
                value="hide-similar"
              >
                Hide Similarities
              </Checkbox>
            </span>
          </div>
        </div>
        <div className="compare-pods__pod-wrapper">
          {itemIds.map((itemId, index) => (
            <div className="compare-pods__section compare-pods__section--pod" key={`image-${index}`}>
              <CustomProductPod
                hasInStoreFilter={hasInStoreFilter}
                hideFavorites={hideFavorites}
                itemId={itemId}
                onRemove={onRemove}
                position={index}
                removable={itemIds.length > 2}
                storeId={storeId}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

ComparePods.displayName = 'ComparePods';

ComparePods.propTypes = {
  itemIds: arrayOf(string).isRequired,
  onHideSimilar: func.isRequired,
  hasInStoreFilter: bool,
  hideSimilar: bool.isRequired,
  onRemove: func.isRequired,
  hideRebates: bool,
  hideFavorites: bool,
};

ComparePods.defaultProps = {
  hasInStoreFilter: false,
  hideRebates: false,
  hideFavorites: false,
};

ComparePods.dataModel = extend({}, CustomProductPod);

export { ComparePods };
