import React, { useContext } from 'react';
import { arrayOf, string, func } from 'prop-types';
import {
  useDataModel,
  extend,
  arrayOf as arrayOfType,
  customType,
  params,
  string as stringType,
  QueryProvider,
  QueryContext
} from '@thd-nucleus/data-sources';
import { CustomProductPod } from '../components/compare-pods/CustomProductPod';

const ProductModel = extend({}, CustomProductPod.dataModel.product);

const CompareSearchNavLoader = ({ itemIds, storeId, children }) => {

  const { isClientResolved } = useContext(QueryContext);
  const skipFn = ({ queryName, attributes, skip, response }) => {
    if (queryName !== 'product') {
      return skip;
    }
    const isResolved = isClientResolved({ queryName: 'searchModel' });
    if (attributes.product.fulfillment) {
      if (!isResolved) {
        return true;
      }
    }
    return skip;
  };

  const opts = {
    ssr: false,
    skip: storeId === '8119',
    variables: {
      itemIds,
      storeId
    }
  };
  const response = useDataModel('searchModel', opts);
  const { data } = response;

  if (!data) return null;

  return (
    <QueryProvider
      dataSource="searchNav"
      cacheKey="compare-searchNav-loader"
      defaultVariables={{
        storeId,
        skipInstallServices: false,
        skipSubscribeAndSave: true
      }}
      skip={skipFn}
    >
      {children(data)}
    </QueryProvider>
  );
};

CompareSearchNavLoader.propTypes = {
  itemIds: arrayOf(string),
  storeId: string,
  children: func.isRequired
};

CompareSearchNavLoader.defaultProps = {
  itemIds: [],
  storeId: ''
};

CompareSearchNavLoader.dataModel = {
  searchModel: params({
    itemIds: arrayOfType(stringType()),
    storeId: stringType(),
    channel: customType('Channel').enum(['DESKTOP', 'MOBILE', 'TABLET'], 'DESKTOP')
  }).shape({
    id: stringType(),
    products: params()
      .arrayOf(ProductModel)
  })
};

export { CompareSearchNavLoader };
