import React from 'react';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { useStore } from '@thd-nucleus/experience-context';
import { Compare } from './Compare';

export const CompareContainer = (props) => {
  const { storeId } = useStore();
  return (
    <QueryProvider
      cacheKey="compare-page-container"
      defaultVariables={{
        storeId,
        skipInstallServices: false
      }}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Compare {...props} />
    </QueryProvider>
  );
};
